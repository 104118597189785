<template>
  <div class="landing-page">
    <transition name="fade" mode="out-in">
      <v-container v-if="isMounted" fluid class="sw-pa-0">
        <!-- header -->
        <div :class="['header-image', { margins: !isTransparent }]">
          <img :src="headerImage" :style="headerImageStyle" />
          <TimeCountdown />
        </div>
        <!-- eo header -->
        <v-row no-gutters class="align-center sw-pt-7 sw-pb-8 intro-wrapper">
          <v-col cols="12">
            <!-- intro -->
            <v-card flat class="mx-auto px-7" max-width="860">
              <div v-html="introText" class="text-center"></div>
            </v-card>
            <!-- eo intro -->
            <!-- registration -->
            <div id="registration-anchor"></div>
            <v-card flat max-width="922" class="mx-auto px-7">
              <Signup />
            </v-card>
            <!-- eo registration -->
          </v-col>
        </v-row>
        <!-- image block -->
        <img :src="fullScreenImage" :style="fullScreenImageStyle" />
        <!-- eo image block -->
      </v-container>
    </transition>
    <AppSnackbar />
    <AppConfirmationModal
      ref="appConfirmationModal"
      :title="$vuetify.lang.t('$vuetify.termsAndConditions')"
      :content="signupPrivacyDisclaimer"
    />
  </div>
</template>

<script>
import TimeCountdown from "@/components/LandingPage/TimeCountdown.vue";
import Signup from "@/components/Signup.vue";
import AppSnackbar from "@/components/AppSnackbar.vue";
import AppConfirmationModal from "@/components/AppConfirmationModal.vue";
import axios from "axios";
import moment from "moment";
import EventBus from "@/utils/eventBus";
import qs from "qs";

export default {
  name: "LandingPage",
  data: () => ({
    isMounted: false,
    signupPlugin: {},
  }),
  computed: {
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    headerHeadline() {
      return this.$store?.getters?.groupAttributes?.lp_header_headline;
    },
    isTransparent() {
      const isTransparent = this.$store?.getters?.group?.attributes
        ?.lp_transparent_toolbar;

      return isTransparent !== null && isTransparent !== undefined
        ? isTransparent
        : false;
    },
    headerImage() {
      const image = this.$store?.getters?.group?.attributes
        ?.lp_header_background_image;
      const defaultImage = require("@/assets/images/header_image.gif");

      return image?.url ? image?.url : defaultImage;
    },
    headerImageStyle() {
      return {
        width: "100%",
        height: "auto",
      };
    },
    introText() {
      return this.$store?.getters?.groupAttributes?.lp_intro_text;
    },
    fullScreenImage() {
      const image = this.$store?.getters?.group?.attributes
        ?.lp_full_screen_image;
      const defaultImage = require("@/assets/images/full_screen_image.gif");

      return image?.url ? image?.url : defaultImage;
    },
    fullScreenImageStyle() {
      return {
        width: "100%",
        height: "auto",
      };
    },
    signupRequiredPrivacyDisclaimer() {
      return this.signupPlugin?.attributes?.signup_required_privacy_disclaimer;
    },
    signupPrivacyDisclaimer() {
      return this.signupPlugin?.attributes?.signup_privacy_disclaimer;
    },
    signupQuery: {
      get() {
        return this.$store?.getters?.signupQuery;
      },
      set(val) {
        this.$store.commit("SET_SIGNUP_QUERY", val);
      },
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store.commit("signup/SET_WINDOW", val);
      },
    },
  },
  methods: {
    async autoLogin() {
      if (!this.$route.query.token) return;

      const query = Object.assign({}, this.$route.query);

      try {
        this.isLoading = true;

        const signupPluginParams = {
          prefix: "signup",
          with_attributes: 1,
          per_page: 100,
        };

        const signupPluginResponse = await this.$http.get(
          `/groups/${this.groupId}/plugins`,
          {
            params: signupPluginParams,
          },
        );

        this.signupPlugin = signupPluginResponse?.data?.data[0];

        const loginParams = {
          token: query.token,
        };

        const loginResponse = await this.$http.get(`/auth/login`, {
          params: loginParams,
        });

        if (!loginResponse?.data?.data?.is_latest_terms_accepted) {
          if (this.signupRequiredPrivacyDisclaimer) {
            const isConfirmed = await this.$refs?.appConfirmationModal?.open();

            if (!isConfirmed) {
              this.isLoading = false;

              return;
            }
          }

          await axios({
            url: "/auth/quick_update",
            method: "post",
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${loginResponse?.data?.data?.token}`,
            },
            params: {
              terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            },
          });
        }

        this.isLoading = false;

        this.$store.commit("SET_USER_TOKEN", loginResponse.data.data.token);

        const questionQuery = Object.fromEntries(
          Object.entries(this.$route.query).filter(
            ([key]) => !key.includes("token"),
          ),
        );

        if (Object.keys(questionQuery).length !== 0) {
          this.window = 1;
          this.signupQuery = qs.parse(questionQuery);
        }

        this.$router.replace({}).catch(() => {});

        EventBus.$emit("showNotification", {
          type: "success",
          message: this.$vuetify.lang.t("$vuetify.loginSuccessfullyDone"),
        });
      } catch (error) {
        if (error) {
          EventBus.$emit("showNotification", {
            type: "error",
            message: this.$vuetify.lang.t("$vuetify.tokenExpired"),
          });

          this.$router.replace({}).catch(() => {});

          this.isLoading = false;
        }

        this.window = 0;
      }
    },
  },
  components: {
    TimeCountdown,
    Signup,
    AppSnackbar,
    AppConfirmationModal,
  },
  mounted() {
    this.$nextTick(() => {
      this.autoLogin();
      this.isMounted = true;
    });
  },
};
</script>

<style lang="scss">
.landing-page {
  .header-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.margins {
      margin-top: 125px;
    }
  }

  .intro-wrapper {
    min-height: 50vh;
  }
}

@media (max-width: 900px) {
  .landing-page {
    .header-image {
      &.margins {
        margin-top: 90px;
      }
    }

    .contact-form-block {
      margin-top: 70px;
      padding: 0 30px;
    }
  }
}
</style>
