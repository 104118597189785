<template>
  <div class="signup-verification">
    <div class="sw-block-subtitle text-center">
      {{ $vuetify.lang.t("$vuetify.enterYourEmail") }}
    </div>
    <v-card flat max-width="428" class="sw-mx-auto">
      <ValidationObserver ref="reminderObserver">
        <v-row no-gutters>
          <v-col cols="12">
            <div id="user-email" class="input-placeholder">
              {{ user.email }}
            </div>
            <div id="email-domain" class="input-placeholder">
              <template v-if="signupEmailDomain">
                @{{ signupEmailDomain }}
              </template>
            </div>
            <div
              class="sw-mx-auto"
              :style="{ width: `${emailInputFieldWidth}px` }"
            >
              <ValidationProvider
                mode="passive"
                :rules="getEmailValidationRule()"
                name="email"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="user.email"
                  :error-messages="errors[0]"
                  :placeholder="$vuetify.lang.t('$vuetify.email')"
                  flat
                  background-color="#f2f2f2"
                  class="rounded-lg registration-field-rounded"
                  solo
                >
                  <template v-slot:append v-if="signupEmailDomain">
                    <span>@{{ signupEmailDomain }}</span>
                  </template>
                </v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="convertToPlain(signupPrivacyDisclaimer)" no-gutters>
          <v-col v-if="signupRequiredPrivacyDisclaimer" cols="12 text-left">
            <ValidationProvider
              vid="terms_and_conditions"
              :name="$vuetify.lang.t('$vuetify.termsAndConditions')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <checkbox
                v-model="user.is_latest_terms_accepted"
                :true-value="true"
                :false-value="null"
                :error-messages="errors[0]"
              >
                <template v-slot:label>
                  <div v-html="signupPrivacyDisclaimer"></div>
                </template>
              </checkbox>
            </ValidationProvider>
          </v-col>
          <v-col v-else cols="12 text-left">
            <div v-html="signupPrivacyDisclaimer"></div>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="remind"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/CustomFields/Checkbox.vue";

export default {
  data: () => ({
    isLoading: false,
    emailInputFieldWidth: 226,
  }),
  components: {
    Checkbox,
  },
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    signupEmailDomain: {
      get() {
        return this.$store?.getters["signup/plugin"]?.attributes
          ?.signup_email_domain;
      },
    },
    signupPrivacyDisclaimer: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_privacy_disclaimer"
        ];
      },
    },
    signupRequiredPrivacyDisclaimer: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_required_privacy_disclaimer"
        ];
      },
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.$nextTick(() => this.setEmailInputFieldWidth());
      },
    },
  },
  mounted() {
    this.setEmailInputFieldWidth();
  },
  methods: {
    setEmailInputFieldWidth() {
      const emailWidth = document.getElementById("user-email")?.clientWidth;
      const domainWidth = document.getElementById("email-domain")?.clientWidth;
      const inputSpacingsWidth = 32;

      if (domainWidth === 0) return;

      this.emailInputFieldWidth = 120 + domainWidth;

      if (emailWidth + domainWidth + inputSpacingsWidth >= 428) {
        this.emailInputFieldWidth = 428;
        return;
      }

      if (emailWidth > 120 - inputSpacingsWidth) {
        this.emailInputFieldWidth =
          emailWidth + domainWidth + inputSpacingsWidth;
      }
    },
    async remind() {
      try {
        const isValid = await this.$refs.reminderObserver.validate();

        if (!isValid) return;
        this.isLoading = true;
        await this.$http.get("/auth/reminder", {
          params: {
            email: this.signupEmailDomain
              ? this.user.email + "@" + this.signupEmailDomain
              : this.user.email,
          },
        });

        this.isLoading = false;
        this.window = 2;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.window = 1;
        }
      }
    },
    getEmailValidationRule() {
      if (this.signupEmailDomain) {
        return "required|emailUser";
      }
      return "required|email";
    },
  },
};
</script>

<style lang="scss">
.signup-verification {
  .registration-field-rounded.v-text-field .v-input__append-inner {
    padding-left: 0px;
  }
  .input-placeholder {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
    font-size: 16px;
  }
}
</style>
