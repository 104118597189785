<template>
  <div class="signup-done">
    <template v-if="plugin.attributes">
      <v-card flat max-width="428" class="sw-mx-auto text-center">
        <div v-html="plugin.attributes.signup_thank_you_content"></div>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
