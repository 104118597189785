<template>
  <div class="signup-registration">
    <v-card flat max-width="428" class="sw-mx-auto">
      <ValidationObserver ref="registerObserver">
        <v-row no-gutters>
          <v-col cols="12">
            <ValidationProvider
              vid="first_name"
              :name="$vuetify.lang.t('$vuetify.name')"
              mode="passive"
              rules="required|min:2"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="user.first_name"
                :error-messages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.name')"
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <ValidationProvider
              vid="last_name"
              :name="$vuetify.lang.t('$vuetify.surname')"
              rules="required|min:2"
              mode="passive"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="user.last_name"
                :error-messages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.surname')"
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <ValidationProvider
              vid="email"
              :name="$vuetify.lang.t('$vuetify.email')"
              rules="required|email"
              mode="passive"
              v-slot="{ errors }"
            >
              <v-text-field
                :value="getEmail"
                :error-messages="errors[0]"
                disabled
                :placeholder="$vuetify.lang.t('$vuetify.email')"
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              >
                <template slot="message">
                  <div class="red--text">{{ errors[0] }}</div>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="sw-pr-2">
            <ValidationProvider
              vid="phone_code"
              :name="$vuetify.lang.t('$vuetify.phoneCode')"
              :rules="phoneCodeRules"
              mode="passive"
              v-slot="{ errors }"
            >
              <CountryCodeSelector
                v-model="user.phone_code"
                :errors="errors"
                class="custom-select registration-field-rounded"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="6" class="sw-pl-2">
            <ValidationProvider
              vid="phone"
              :name="$vuetify.lang.t('$vuetify.phone')"
              :rules="phoneNumberRules"
              mode="passive"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="user.phone"
                :error-messages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.phone')"
                flat
                background-color="#f2f2f2"
                class="
                  rounded-lg
                  registration-field-rounded
                  sw-rounded
                  w-field-height-48px
                "
                solo
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="convertToPlain(signupPrivacyDisclaimer)" no-gutters>
          <v-col v-if="signupRequiredPrivacyDisclaimer" cols="12 text-left">
            <ValidationProvider
              vid="terms_and_conditions"
              :name="$vuetify.lang.t('$vuetify.termsAndConditions')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <checkbox
                v-model="user.is_latest_terms_accepted"
                :true-value="true"
                :false-value="null"
                :error-messages="errors[0]"
              >
                <template v-slot:label>
                  <div v-html="signupPrivacyDisclaimer"></div>
                </template>
              </checkbox>
            </ValidationProvider>
          </v-col>
          <v-col v-else cols="12 text-left">
            <div v-html="signupPrivacyDisclaimer"></div>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
    <div class="sw-pt-7 text-center">
      <v-btn
        v-if="!user.id"
        rounded
        large
        depressed
        class="
          sw-mx-2
          text-none
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading || loading"
        @click="window = 0"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          sw-mx-2
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading || loading"
        @click="next"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CountryCodeSelector from "@/components/CustomFields/CountryCodeSelector.vue";
import Checkbox from "@/components/CustomFields/Checkbox.vue";
import EventBus from "@/utils/eventBus";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    isUserSubscribed() {
      return this.user?.groups?.includes(this.groupId);
    },
    appLanguage() {
      return this.$store.getters?.appLanguage;
    },
    window: {
      get() {
        return this.$store?.getters["signup/window"];
      },
      set(val) {
        this.$store?.commit("signup/SET_WINDOW", val);
      },
    },
    plugin() {
      return this.$store?.getters["signup/plugin"];
    },
    isVerificationEnabled() {
      return this.plugin?.attributes?.signup_verification_enabled || false;
    },
    isPhoneRequired() {
      return this.plugin?.attributes?.signup_phone_required || false;
    },
    phoneCodeRules() {
      const required =
        this.isPhoneRequired || this.user.phone ? "required|" : "";
      return `${required}numeric|max:3`;
    },
    phoneNumberRules() {
      const required = this.isPhoneRequired ? "required|" : "";
      const rules =
        process?.env?.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";

      return `${required}${rules}`;
    },
    signupEmailDomain: {
      get() {
        return this.$store?.getters["signup/plugin"]?.attributes
          ?.signup_email_domain;
      },
    },
    getEmail() {
      return this.signupEmailDomain
        ? this.user.email.split("@")[0] + "@" + this.signupEmailDomain
        : this.user.email;
    },
    defaultDialCode() {
      return process.env.VUE_APP_DEFAULT_DIAL_CODE;
    },
    signupPrivacyDisclaimer: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_privacy_disclaimer"
        ];
      },
    },
    signupRequiredPrivacyDisclaimer: {
      get() {
        return this.$store?.getters["signup/plugin"]["attributes"][
          "signup_required_privacy_disclaimer"
        ];
      },
    },
  },
  components: {
    CountryCodeSelector,
    Checkbox,
  },
  methods: {
    async register() {
      const specs = {
        email: this.getEmail,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone_code: this.user.phone ? this.user.phone_code : "",
        phone: this.user.phone,
        lang_pref: [this.appLanguage],
        terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        password: Math.random()
          .toString(36)
          .substr(2, 8),
      };

      const response = await this.$http.post("/auth/register", specs);

      if (!this.user.phone) {
        this.user.phone_code = "";
      }

      if (this.isVerificationEnabled) {
        const params = { email: this.getEmail };

        await this.$http.get("/auth/reminder", { params });

        this.window = 2;
      } else {
        const user = response?.data?.data || { lang_pref: "" };
        const token = response?.data?.data?.token || "";

        this.$store.commit("SET_USER", user);
        this.$store?.commit("SET_USER_TOKEN", token);

        this.$emit("registered");
      }
    },
    async update() {
      this.isLoading = true;

      const lang_pref = this.user.lang_pref || [];
      const index = lang_pref.indexOf(this.appLanguage);

      if (index !== -1) {
        lang_pref.splice(index, 1);
      }

      lang_pref.unshift(this.appLanguage);

      const specs = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone_code: this.user.phone ? this.user.phone_code : "",
        phone: this.user.phone,
        lang_pref: lang_pref,
        terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      await this.$http.post("/auth/quick_update", specs);

      if (!this.user.phone) {
        this.user.phone_code = "";
      }

      this.$emit("registered");
    },
    async subscribe() {
      await this.$http.get(`/auth/subscribe`, {
        params: { groups: this.groupId },
      });

      this.user?.groups?.push(this.groupId);
    },
    async next() {
      try {
        const isValid = await this.$refs.registerObserver.validate();

        if (!isValid) return;

        this.isLoading = true;

        if (!this.user?.id) {
          await this.register();
        } else {
          await this.update();
        }

        if (!this.isUserSubscribed) {
          await this.subscribe();
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        const errorMessage = error?.response?.data?.error?.message;

        if (errorMessage === "The phone has already been taken.") {
          this.$refs.registerObserver.setErrors({
            phone: [this.$vuetify.lang.t("$vuetify.phoneAlreadyTaken")],
          });
          return;
        }

        if (errorMessage === "The email has already been taken.") {
          this.$refs.registerObserver.setErrors({
            email: [this.$vuetify.lang.t("$vuetify.emailAlreadyTaken")],
          });
          return;
        }

        EventBus.$emit("showNotification", {
          type: "error",
          message: errorMessage,
        });
      }
    },
  },
  mounted() {
    this.$set(
      this.user,
      "phone_code",
      this.user.phone_code || this.defaultDialCode,
    );
  },
};
</script>

<style lang="scss" scoped></style>
