<template>
  <div class="mx-auto time-countdown">
    <v-row class="no-gutters">
      <v-col cols="12" class="white--text text-center">
        <div
          v-if="showHeaderHeadline"
          class="sw-px-3 font-weight-black countdown-title"
        >
          {{ headerHeadline }}
        </div>
        <div
          v-if="showEventStartText"
          class="font-weight-black countdown-subtitle"
        >
          {{ eventStartText }}
        </div>
      </v-col>
      <transition name="fade" mode="out-in">
        <v-col
          v-show="
            isCountdownVisible && totalMiliseconds && totalSecondsLeft > 0
          "
          cols="12"
          class="white--text text-center"
        >
          <countdown
            :time="totalMiliseconds"
            @progress="handleCountdownProgress"
          >
            <template slot-scope="props">
              <v-row class="no-gutters text-center">
                <v-col cols="3" class="pa-sm-3">
                  <div class="mb-2 countdown-time-value font-weight-black">
                    {{ props.days }}
                  </div>
                  <span class="countdown-time-text font-weight-light">{{
                    props.days > 1
                      ? $vuetify.lang.t("$vuetify.countdown.days")
                      : $vuetify.lang.t("$vuetify.countdown.day")
                  }}</span>
                </v-col>
                <v-col cols="3" class="pa-sm-3">
                  <div class="mb-2 countdown-time-value font-weight-black">
                    {{ props.hours }}
                  </div>
                  <span class="countdown-time-text font-weight-light">{{
                    props.hours > 1
                      ? $vuetify.lang.t("$vuetify.countdown.hours")
                      : $vuetify.lang.t("$vuetify.countdown.hour")
                  }}</span>
                </v-col>
                <v-col cols="3" class="pa-sm-3">
                  <div class="mb-2 countdown-time-value font-weight-black">
                    {{ props.minutes }}
                  </div>
                  <span class="countdown-time-text font-weight-light">{{
                    props.minutes > 1
                      ? $vuetify.lang.t("$vuetify.countdown.minutes")
                      : $vuetify.lang.t("$vuetify.countdown.minute")
                  }}</span>
                </v-col>
                <v-col cols="3" class="pa-sm-3">
                  <div class="mb-2 countdown-time-value font-weight-black">
                    {{ props.seconds }}
                  </div>
                  <span class="countdown-time-text font-weight-light">{{
                    props.seconds > 1
                      ? $vuetify.lang.t("$vuetify.countdown.seconds")
                      : $vuetify.lang.t("$vuetify.countdown.second")
                  }}</span>
                </v-col>
              </v-row>
            </template>
          </countdown>
        </v-col>
      </transition>
    </v-row>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import moment from "moment";

export default {
  data: () => ({
    totalSecondsLeft: 0,
  }),
  computed: {
    isCountdownVisible() {
      return this.$store?.getters?.group?.attributes?.lp_event_timer;
    },
    eventStartDate() {
      return this.$store?.getters?.group?.attributes?.lp_event_start_date;
    },
    date() {
      return moment(this.eventStartDate).diff(
        moment(new Date()),
        "milliseconds",
      );
    },
    totalMiliseconds() {
      return this.date > 0 ? this.date : 0;
    },
    headerHeadline() {
      return this.$store?.getters?.groupAttributes?.lp_header_headline;
    },
    eventStartText() {
      return this.$store?.getters?.groupAttributes?.lp_event_start_text;
    },
    showHeaderHeadline() {
      return (
        !this.isCountdownVisible ||
        !this.eventStartText ||
        this.totalSecondsLeft > 0
      );
    },
    showEventStartText() {
      return !!(
        this.isCountdownVisible &&
        this.totalSecondsLeft === 0 &&
        this.eventStartText
      );
    },
  },
  components: {
    countdown: VueCountdown,
  },
  methods: {
    handleCountdownProgress(data) {
      this.totalSecondsLeft = data.totalSeconds;
    },
  },
};
</script>

<style scoped lang="scss">
.time-countdown {
  position: absolute;
  min-width: 70%;
  max-width: 70%;

  .countdown-title {
    margin-bottom: 32px;
  }

  .countdown-title,
  .countdown-subtitle {
    font-size: 3.75rem;
    line-height: 1.3;
  }

  .countdown-time-value {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .countdown-time-text {
    font-size: 2.125rem;
    line-height: 1.3;
  }
}

@media (max-width: 900px) {
  .time-countdown {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;

    .countdown-title {
      margin-bottom: 14px;
    }

    .countdown-title,
    .countdown-subtitle {
      font-size: 28px;
      line-height: 28px;
    }

    .countdown-time-value {
      font-size: 28px;
      line-height: 28px;
    }

    .countdown-time-text {
      font-size: 15px;
    }
  }
}
</style>
